var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var sizes = exports.sizes = {
    tiny: "md-18",
    small: "md-24",
    medium: "md-36",
    large: "md-48"
  };
  var light = exports.light = "md-light";
  var dark = exports.dark = "md-dark";
  var mdInactive = exports.mdInactive = "md-inactive";
});

export default exports;