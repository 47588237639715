import _pallet2 from "./pallet";
import _react3 from "react";
import _mappings2 from "./config/mappings";
import _index from "./index.css";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (global, factory) {
  factory(exports, _pallet2, _react3, _mappings2, _index);
})(exports, function (exports, _pallet, _react, _mappings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.keys(_pallet).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(exports, key, {
      enumerable: true,
      get: function () {
        return _pallet[key];
      }
    });
  });

  var _react2 = _interopRequireDefault(_react);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var MaterialIcon = function (_React$Component) {
    _inherits(MaterialIcon, _React$Component);

    function MaterialIcon() {
      _classCallCheck(this || _global, MaterialIcon);

      return _possibleConstructorReturn(this || _global, (MaterialIcon.__proto__ || Object.getPrototypeOf(MaterialIcon)).apply(this || _global, arguments));
    }

    _createClass(MaterialIcon, [{
      key: "render",
      value: function render() {
        var _props = (this || _global).props,
            icon = _props.icon,
            size = _props.size,
            invert = _props.invert,
            inactive = _props.inactive,
            color = _props.color;

        var sizeMapped = _mappings.sizes[size] || parseInt(size) || _mappings.sizes["small"];

        var defaultColor = invert && "invert" ? _mappings.light : _mappings.dark;
        var inactiveColor = inactive && "inactive" ? _mappings.mdInactive : "";
        var styleOverride = {
          color: color ? color : "",
          fontSize: sizeMapped
        };
        return _react2.default.createElement("i", {
          className: "material-icons " + sizeMapped + " " + defaultColor + " " + inactiveColor,
          style: styleOverride
        }, icon);
      }
    }]);

    return MaterialIcon;
  }(_react2.default.Component);

  ;
  exports.default = MaterialIcon;
});

export default exports;