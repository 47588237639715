var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var colorPallet = exports.colorPallet = {
    red: {
      default: "#f44336",
      _500: "#f44336",
      _50: "#ffebee",
      _100: "#ffcdd2",
      _200: "#ef9a9a",
      _300: "#e57373",
      _400: "#ef5350",
      _600: "#e53935",
      _700: "#d32f2f",
      _800: "#c62828",
      _900: "#b71c1c",
      A100: "#ff8a80",
      A200: "#ff5252",
      A400: "#ff1744",
      A700: "#d_50000"
    },
    pink: {
      default: "#E91E63",
      _500: "#E91E63",
      _50: "#FCE4EC",
      _100: "#F8BBD0",
      _200: "#F48FB1",
      _300: "#F06292",
      _400: "#EC407A",
      _600: "#D81B60",
      _700: "#C2185B",
      _800: "#AD1457",
      _900: "#880E4F",
      A100: "#FF80AB",
      A200: "#FF4081",
      A400: "#F_50057",
      A700: "#C51162"
    },
    purple: {
      default: "#9C27B0",
      _500: "#9C27B0",
      _50: "#F3E5F5",
      _100: "#E1BEE7",
      _200: "#CE93D8",
      _300: "#BA68C8",
      _400: "#AB47BC",
      _600: "#8E24AA",
      _700: "#7B1FA2",
      _800: "#6A1B9A",
      _900: "#4A148C",
      A100: "#EA80FC",
      A200: "#E040FB",
      A400: "#D_500F9",
      A700: "#AA00FF"
    },
    deepPurple: {
      default: "#673AB7",
      _500: "#673AB7",
      _50: "#EDE7F6",
      _100: "#D1C4E9",
      _200: "#B39DDB",
      _300: "#9575CD",
      _400: "#7E57C2",
      _600: "#5E35B1",
      _700: "#512DA8",
      _800: "#4527A0",
      _900: "#311B92",
      A100: "#B388FF",
      A200: "#7C4DFF",
      A400: "#651FFF",
      A700: "#6200EA"
    },
    indigo: {
      default: "#3F51B5",
      _500: "#3F51B5",
      _50: "#E8EAF6",
      _100: "#C5CAE9",
      _200: "#9FA8DA",
      _300: "#7986CB",
      _400: "#5C6BC0",
      _600: "#3949AB",
      _700: "#303F9F",
      _800: "#283593",
      _900: "#1A237E",
      A100: "#8C9EFF",
      A200: "#536DFE",
      A400: "#3D5AFE",
      A700: "#304FFE"
    },
    blue: {
      default: "#2196F3",
      _500: "#2196F3",
      _50: "#E3F2FD",
      _100: "#BBDEFB",
      _200: "#90CAF9",
      _300: "#64B5F6",
      _400: "#42A5F5",
      _600: "#1E88E5",
      _700: "#1976D2",
      _800: "#1565C0",
      _900: "#0D47A1",
      A100: "#82B1FF",
      A200: "#448AFF",
      A400: "#2979FF",
      A700: "#2962FF"
    },
    lightBlue: {
      default: "#03A9F4",
      _500: "#03A9F4",
      _50: "#E1F5FE",
      _100: "#B3E5FC",
      _200: "#81D4FA",
      _300: "#4FC3F7",
      _400: "#29B6F6",
      _600: "#039BE5",
      _700: "#0288D1",
      _800: "#0277BD",
      _900: "#01579B",
      A100: "#80D8FF",
      A200: "#40C4FF",
      A400: "#00B0FF",
      A700: "#0091EA"
    },
    cyan: {
      default: "#00BCD4",
      _500: "#00BCD4",
      _50: "#E0F7FA",
      _100: "#B2EBF2",
      _200: "#80DEEA",
      _300: "#4DD0E1",
      _400: "#26C6DA",
      _600: "#00ACC1",
      _700: "#0097A7",
      _800: "#00838F",
      _900: "#006064",
      A100: "#84FFFF",
      A200: "#18FFFF",
      A400: "#00E5FF",
      A700: "#00B8D4"
    },
    teal: {
      default: "#009688",
      _500: "#009688",
      _50: "#E0F2F1",
      _100: "#B2DFDB",
      _200: "#80CBC4",
      _300: "#4DB6AC",
      _400: "#26A69A",
      _600: "#00897B",
      _700: "#00796B",
      _800: "#00695C",
      _900: "#004D40",
      A100: "#A7FFEB",
      A200: "#64FFDA",
      A400: "#1DE9B6",
      A700: "#00BFA5"
    },
    green: {
      default: "#4CAF50",
      _500: "#4CAF50",
      _50: "#E8F5E9",
      _100: "#C8E6C9",
      _200: "#A5D6A7",
      _300: "#81C784",
      _400: "#66BB6A",
      _600: "#43A047",
      _700: "#388E3C",
      _800: "#2E7D32",
      _900: "#1B5E20",
      A100: "#B9F6CA",
      A200: "#69F0AE",
      A400: "#00E676",
      A700: "#00C853"
    },
    lightGreen: {
      default: "#8BC34A",
      _500: "#8BC34A",
      _50: "#F1F8E9",
      _100: "#DCEDC8",
      _200: "#C5E1A5",
      _300: "#AED581",
      _400: "#9CCC65",
      _600: "#7CB342",
      _700: "#689F38",
      _800: "#558B2F",
      _900: "#33691E",
      A100: "#CCFF90",
      A200: "#B2FF59",
      A400: "#76FF03",
      A700: "#64DD17"
    },
    lime: {
      default: "#CDDC39",
      _500: "#CDDC39",
      _50: "#F9FBE7",
      _100: "#F0F4C3",
      _200: "#E6EE9C",
      _300: "#DCE775",
      _400: "#D4E157",
      _600: "#C0CA33",
      _700: "#AFB42B",
      _800: "#9E9D24",
      _900: "#827717",
      A100: "#F4FF81",
      A200: "#EEFF41",
      A400: "#C6FF00",
      A700: "#AEEA00"
    },
    yellow: {
      default: "#FFEB3B",
      _500: "#FFEB3B",
      _50: "#FFFDE7",
      _100: "#FFF9C4",
      _200: "#FFF59D",
      _300: "#FFF176",
      _400: "#FFEE58",
      _600: "#FDD835",
      _700: "#FBC02D",
      _800: "#F9A825",
      _900: "#F57F17",
      A100: "#FFFF8D",
      A200: "#FFFF00",
      A400: "#FFEA00",
      A700: "#FFD600"
    },
    amber: {
      default: "#FFC107",
      _500: "#FFC107",
      _50: "#FFF8E1",
      _100: "#FFECB3",
      _200: "#FFE082",
      _300: "#FFD54F",
      _400: "#FFCA28",
      _600: "#FFB300",
      _700: "#FFA000",
      _800: "#FF8F00",
      _900: "#FF6F00",
      A100: "#FFE57F",
      A200: "#FFD740",
      A400: "#FFC400",
      A700: "#FFAB00"
    },
    orange: {
      default: "#FF9800",
      _500: "#FF9800",
      _50: "#FFF3E0",
      _100: "#FFE0B2",
      _200: "#FFCC80",
      _300: "#FFB74D",
      _400: "#FFA726",
      _600: "#FB8C00",
      _700: "#F57C00",
      _800: "#EF6C00",
      _900: "#E65100",
      A100: "#FFD180",
      A200: "#FFAB40",
      A400: "#FF9100",
      A700: "#FF6D00"
    },
    deepOrange: {
      default: "#FF5722",
      _500: "#FF5722",
      _50: "#FBE9E7",
      _100: "#FFCCBC",
      _200: "#FFAB91",
      _300: "#FF8A65",
      _400: "#FF7043",
      _600: "#F4511E",
      _700: "#E64A19",
      _800: "#D84315",
      _900: "#BF360C",
      A100: "#FF9E80",
      A200: "#FF6E40",
      A400: "#FF3D00",
      A700: "#DD2C00"
    },
    brown: {
      default: "#795548",
      _500: "#795548",
      _50: "#EFEBE9",
      _100: "#D7CCC8",
      _200: "#BCAAA4",
      _300: "#A1887F",
      _400: "#8D6E63",
      _600: "#6D4C41",
      _700: "#5D4037",
      _800: "#4E342E",
      _900: "#3E2723"
    },
    grey: {
      default: "#9E9E9E",
      _500: "#9E9E9E",
      _50: "#FAFAFA",
      _100: "#F5F5F5",
      _200: "#EEEEEE",
      _300: "#E0E0E0",
      _400: "#BDBDBD",
      _600: "#757575",
      _700: "#616161",
      _800: "#424242",
      _900: "#212121"
    },
    blueGrey: {
      default: "#607D8B",
      _500: "#607D8B",
      _50: "#ECEFF1",
      _100: "#CFD8DC",
      _200: "#B0BEC5",
      _300: "#90A4AE",
      _400: "#78909C",
      _600: "#546E7A",
      _700: "#455A64",
      _800: "#37474F",
      _900: "#263238"
    }
  };
});

export default exports;